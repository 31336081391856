import * as React from 'react'
import {Link} from 'gatsby'
import LoginStatus from './LoginStatus'
import ceohLogo from '../images/logo-ceoh-nav.png'


export default function NavBar({global, dispatch}) {

  let navMenuItems

  if (global.current_user_vuid) {
    navMenuItems = [
      {label: 'Home', route: '/'},
      // {label: 'Mentors', route: '/mentors-access'},
      {label: 'Learners', route: '/learners-access'},
    ]
  }
  else {
    navMenuItems = [
      {label: 'Home', route: '/'},
      // {label: 'Mentors', route: '/mentors'},
      {label: 'Learners', route: '/learners-access-open'},
    ]
  }


  return (

    <nav className='navbar navbar-expand-md' role='navigation'>
			 <div className='container-fluid'>

        <Link to='/' className='navbar-brand'>
          <img src={ceohLogo} alt='VERA' />
        </Link>

        <ul className='nav'>

          {navMenuItems.map((item) => (
            <li key={item.route} className='nav-item'>
              <Link to={item.route} className='nav-link'>

                {item.label.toUpperCase()}

              </Link>
            </li>
          ))}

        </ul>

      </div>

      <LoginStatus global={global} dispatch={dispatch} />
    </nav>
  )
}
